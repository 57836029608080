/* You can add global styles to this file, and also import other style files */
@import './styles/main';

:root {
  --primary-color: #4db70d;
  --cds-primary-color: #4db70d;
  --primary-color-light: #e9efe6;
  --box-shadow: 0px 13px 18px 0px #11132314;
  --color-light: #666;
  --cds-form-input-background: #e9efe6;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
a {
  color: inherit;
}

.section {
  padding-top: 4rem;
  padding-bottom: 8rem;
  padding-inline: 1.5rem;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.col {
  flex-grow: 1;
  display: flex;
}

.shadow {
  box-shadow: var(--box-shadow);
}

.text--wrap {
  word-break: break-all;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}
.tracking-form {
  width: 100%;
  display: flex;
  gap: 0.5rem;

  &__input {
    flex: 0 0 80%;
    max-width: 80%;
  }

  &__button {
    flex: 0 0 20%;
    width: 15vw;
    max-width: 20%;
    text-transform: uppercase;
    padding: 0;
  }
}

.input__container .input__group {
  border-radius: 20px;
}

.input__container .input__group--lg {
  height: 60px;
}

.dropdown__preview {
  height: 60px;
  border-radius: 20px;
}

.info__window__container {
  min-width: 250px;

  .info__window__info {
    display: grid;
    gap: 10px;
    grid-template-columns: 50% calc(50% - 10px);
    margin-bottom: 10px;
  }

  .info__window__info__strong {
    font-weight: 600;
  }

  .info__window__title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

@include until-desktop {
  p {
    font-size: 14px;
  }
}

@include mobile {
  .section {
    padding-bottom: 4rem;
  }
}

@include mobile-sm() {
  .tracking-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__input {
      width: 100%;
    }

    &__button {
      width: 15vw;
      text-transform: uppercase;
      padding: 0;
    }
  }
}
